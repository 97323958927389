// copy of nippleJS library
import nipplejs from 'nipplejs'

const style = 'border-radius:999px;position:fixed;display:block;width:100px;height:100px;left:20px;bottom:20px;background-color:#fa9b00;z-index:20'

function initJoystick() {
// create element
  let d = document.createElement('div')
  d.setAttribute('id', 'np')
  d.setAttribute('style', style)
  document.querySelector('body').appendChild(d)

  // create text overlay
  var p = document.createElement('div')
  p.setAttribute('class', 'joystick')
  p.setAttribute('style', 'height:100px;display:table-cell;vertical-align:middle;font-family: Helvetica, Arial, sans-serif;text-align: center;font-size:12px ; opacity:.5;')
  p.innerHTML = 'Drücken und halten zum bewegen oder Pfeiltasten nutzen'
  d.appendChild(p)
}

var moveData = ''

// create standard NipplesJS joystick
function createJoystick() {
  initJoystick()
  var options = {
    mode: 'dynamic',
    zone: document.getElementById('np'),
    color: '#0F0000',
    fadeTime: 10,
  }

  var manager = nipplejs.create(options)
  bindNipple()

  function bindNipple() {
    manager.on('move', function (evt, data) {
      moveData = data
    })
    manager.on('end', function () {
      moveData = ''
    })
  }

}

// turn joystick data into WASD movement in AFRAME


function updatePosition(data) {
  let f = data.force
  let ang = data.angle.radian
  let cam = document.getElementById('camera')


  let x_vec = Math.cos(ang + 3.14 / 180 * cam.getAttribute('rotation')['y'])
  let y_vec = Math.sin(ang + 3.14 / 180 * cam.getAttribute('rotation')['y'])

  let x = cam.getAttribute('position')['x'] + f / 15 * (x_vec)
  let y = cam.getAttribute('position')['y']
  let z = cam.getAttribute('position')['z'] - f / 15 * (y_vec)

  cam.setAttribute('position', `${x} ${y} ${z}`)
}

// eslint-disable-next-line
AFRAME.registerComponent('joystick', {
  init: function () {
    createJoystick()
  },
  tick: function () {
    if (moveData != '') {
      updatePosition(moveData)
    }
  },
  remove: function () {

    document.getElementById('np').remove()

  },
})
