<template>
  <a-entity>
    <banner-top scale="1.5 1.5 1.5 "
                position="0 0 0" :hallid="hallid"
    />

    <a-entity position="-1 0 1"
              rotation="0 90 0"
              scale="0.5 0.5 0.5 "

              :gltf-model="'url( '+publicPath+'/models/v3o.glb)'"
    />
  </a-entity>
</template>

<script>
import BannerTop from '@/components/banner/banner-top'
export default {
  components: {BannerTop},
  data() {
    return {
      publicPath: process.env.BASE_URL
    }
  },
  props: ['hallid'],

}
</script>
