<template>
  <a-entity rotation="0 0 0" animation="property: rotation; to: 0 360 0; loop: true; dur: 55000;easing: linear; ">
    <a-curvedimage :src="require('../../assets/hallenbilder/0-1.jpg')" height="8.0" radius="26" theta-length="30" rotation="0 60 0" scale="1 1 1"
                   position="0 15 0"
    />

    <a-curvedimage :src="require(`../../assets/hallenbilder/${hallid}-2.jpg`)" height="8.0" radius="26" theta-length="30" rotation="0 120 0" scale="1 1 1"
                   position="0 15 0"
    />

    <a-curvedimage :src="require('../../assets/hallenbilder/0-3.jpg')" height="8.0" radius="26" theta-length="30" rotation="0 180 0" scale="1 1 1"
                   position="0 15 0"
    />

    <a-curvedimage :src="require('../../assets/hallenbilder/'+hallid+'-4.jpg')" height="8.0" radius="26" theta-length="30" rotation="0 240 0" scale="1 1 1"
                   position="0 15 0"
    />

    <a-curvedimage  :src="require('../../assets/hallenbilder/0-5.jpg')" height="8.0" radius="26" theta-length="30" rotation="0 300 0" scale="1 1 1"
                   position="0 15 0"
    />

    <a-curvedimage :src="require('../../assets//hallenbilder/'+hallid+'-6.jpg')" height="8.0" radius="26" theta-length="30" rotation="0 0 0" scale="1 1 1"
                   position="0 15 0"
    />
  </a-entity>
</template>

<script>
export default {
  props: ['hallid'],
}
</script>
