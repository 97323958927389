<template>
  <a-entity class="raycastable" @click="clickHandler()">
    <a-entity
      rotation="0 -90 0"
      scale="0.6 0.6 0.6 "
      :gltf-model="'url( '+publicPath+'models/staender.glb)'"
    />

    <a-image v-if="prospect && prospect.preview" id="prospectbild"
             class="raycastable"
             color="#ddd"
             position="0 1.37 0"
             rotation="-43 -90 0"
             height="0.323"
             width="0.3"
             :src="prospect.preview"
             @click="clickHandler()"
    />

    <a-text
      :font="publicPath+'/3dfont/ClanOffc-NarrMedium-msdf.json'"
      align="center"
      baseline="top"

      negate="false"
      color="black"
      width="0.6"
      value="Klicken zum\nansehen."
      position="-0.15 1.25 0"
      rotation="-43 -90 0"
    />
  </a-entity>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL
    }
  },
  props: ['prospect', 'firma'],
  methods: {
    clickHandler: function () {
      this.$gtag.event('click', {
        event_category: 'Prospekt',
        event_label: 'Firma ' + this.firma.Firma,
        value: 1
      })
      window.open(this.prospect.pdf, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=800,width=700,height=400')
    },
  },
}
</script>
