<template>
  <a-entity>
    <a-entity
      rotation="0 -90 0"
      scale="1.5 1.1 1"
      :gltf-model="'url( '+publicPath+'/models/tafel.glb)'  "
    />


    <a-text
      :font="publicPath+'/3dfont/ClanOffc-NarrMedium-msdf.json'"
      align="center"
      baseline="top"
      negate="false"
      color="white"
      width="1.3"
      :value="infotext"
      position="-0.059 0.95 0.7"
      rotation="0 -90 0"
    />

    <a-image
      position="-0.059 0.43 0.35" rotation="0 -90 0"
      width="0.2"
      height="0.2"
      src="#mail" side="front"
      class="raycastable" @click="clickHandlerMail()"
    />

    <a-image
      position="-0.059 0.43 0.675" rotation="0 -90 0"
      width="0.2"
      height="0.2"
      src="#web" side="front"
      class="raycastable" @click="clickHandlerWebsite()"
    />

    <a-image
      position="-0.059 0.43 1" rotation="0 -90 0"
      width="0.2"
      height="0.2"
      src="#tel" side="front"
      class="raycastable" @click="clickHandlerTel()"
    />
  </a-entity>
</template>

<script>
export default {
  props: ['firma'],
  data() {
    return {
      publicPath: process.env.BASE_URL
    }
  },
  computed: {
    infotext() {
      let firma = this.firma
      let etxt = 'Dein Ansprechpartner:' + '\n' + '\n'
      if (firma.Ansprechpartner)
        etxt += firma.Ansprechpartner + '\n'
      if (firma.Telefon)
        etxt += firma.Telefon + '\n'
      if (firma.Mailadresse && firma.Mailadresse.length > 30)
        etxt += firma.Mailadresse.replace('@', '@\n') + '\n'
      if (firma.Mailadresse && firma.Mailadresse.length <= 30)
        etxt += firma.Mailadresse + '\n'
      return etxt
    },
  },
  methods: {
    clickHandlerTel: function () {
      this.$gtag.event('click', {
        event_category: 'Telefon',
        event_label: 'Mit Firma ' + this.firma.Firma,
        value: 1
      })
      window.open('tel:' + this.firma.Telefon, '_blank')

    },
    clickHandlerMail: function () {
      this.$gtag.event('click', {
        event_category: 'Mail',
        event_label: 'Mit Firma ' + this.firma.Firma,
        value: 1
      })
      window.open('mailto:' + this.firma.Mailadresse, '_blank')
    },
    clickHandlerWebsite: function () {
      this.$gtag.event('click', {
        event_category: 'Website',
        event_label: 'Mit Firma ' + this.firma.Firma,
        value: 1
      })
      window.open(  this.firma.Webseite, '_blank')
    },
  },
}
</script>
