<template>
  <a-entity>
    <a-entity
      rotation="0 -270 0"
      scale="1.5 1.1 1"
      :gltf-model="'url( '+publicPath+'/models/tafel.glb)'  "
    />

    <a-text
      :font="publicPath+'/3dfont/ClanOffc-NarrMedium-msdf.json'"
      align="center"
      baseline="top"
      negate="false"
      color="white"
      width="1.3"
      :value="infotext"
      position="-0.059 0.9 -0.7"
      rotation="0 -90 0"
    />

    <a-image
      position="-0.059 0.45 -0.7" rotation="0 -90 0"
      width="0.2"
      height="0.2"
      src="#chat" side="front"
      class="raycastable" @click="startchat()"
    />
  </a-entity>
</template>

<script>
export default {
  props: ['firma'],
  data() {
    return {
      publicPath: process.env.BASE_URL
    }
  },
  computed: {

    infotext() {

      return 'Wir sind im Chat erreichbar.' + '\nKlicke auf das Icon zum starten.'
    },
  },
  methods: {
    startchat: function () {

      // console.log('chat with ' + this.firma.id)
      //console.log(this.$parent.$parent.$refs)
      this.$parent.$parent.$parent.$refs.chatwidget.joinViaFirmaId(this.firma.id)
      this.$root.chatvisible = 1
      this.$gtag.event('click', {
        event_category: 'chat',
        event_label: 'Mit Firma ' + this.firma.Firma,
        value: 1
      })
    },
  },
}
</script>
