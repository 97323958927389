<template>
  <a-entity v-if="firma">
    <a-entity rotation="0 90 0">
      <a-plane id="fussbodenstand" position="0 0.03 0" rotation="-90 0 0" width="6" height="10"
               color="#222"
               shadow="receive:true;"
               roughness="1"
      />
      <a-text v-if="firma.Firma"
              :font="publicPath+'/3dfont/ClanOffc-NarrMedium-msdf.json'"
              align="right"
              baseline="top"
              wrap-pixels="812"
              negate="false"
              :color="contrastColor()"
              width="3"
              :value="firma.Firma"
              position="2.85 2.8 4.5" rotation="0 -90 0 "
      />

      <a-entity id="boxleft" position="-2.6 0.6 -2.8">
        <a-box id="base"
               rotation="0 90 0"
               color="white"
               roughness="0.6"
               shadow="cast:true; receive:true;"
               scale="3 1.2 0.4"
        />
        <a-box id="top"
               position="0 0.6 0"
               rotation="0 90 0"
               :color="firma.Maincolor"
               roughness="0.6"
               scale="3.2 0.03 0.42"
        />

        <a-text v-if="firma.Text"
                :font="publicPath+'/3dfont/ClanOffc-NarrMedium-msdf.json'"
                align="left"
                baseline="top"
                wrap-count="48"
                negate="false"
                color="black"
                width="2.6"
                :value="firma.Text"
                rotation="0 -90 0 "
                position="-0.2003 0.4 -1.3"
        />
      </a-entity>

      <a-entity id="boxright" position="-2.6 0.6  2.8">
        <a-box id="base"
               rotation="0 90 0"
               color="white"
               roughness="0.6"
               shadow="cast:true; receive:true;"
               scale="3 1.2 0.4"
        />
        <a-box id="top"
               position="0 0.6 0"
               rotation="0 90 0"
               :color="firma.Maincolor"
               roughness="0.6"
               scale="3.2 0.03 0.42"
        />
        <a-image
          v-if="firma.Logo"
          id="logo"
          rotation="0 -90 0 "
          position="-0.3 0.05 0"
          height="1"
          width="1"
          side="front"
          :src="firma.Logo"
        />
      </a-entity>

      <a-image
        v-if="firma.fotos && firma.fotos[0]"
        id="wandhintenbild"
        position="2.87 1.5 -2.3"
        rotation="0 -90 0"
        height="2.8"
        width="4.9777777"
        side="front"
        :src="currentimg"
      />


      <a-triangle
        v-if="firma.fotos && firma.fotos.length>1"
        id="pf-left"
        position="2.7 1.5 -4.2"
        rotation="0 -90 90"
        height="0.6"
        width="0.6"
        color="#FFF"
        vertex-a="0 0.2 0"
        vertex-b="-0.2 -0.2 0"
        vertex-c="0.2 -0.2 0"
        class="raycastable" @click="gotobefore()"></a-triangle>

      <a-triangle
        v-if="firma.fotos && firma.fotos.length>1"
        id="pf-left"
        position="2.71 1.5 -4.2"
        rotation="0 -90 90"
        height="0.6"
        width="0.6"
        color="#000"
        vertex-a="0 0.21 0"
        vertex-b="-0.21 -0.21 0"
        vertex-c="0.21 -0.21 0"
        class="raycastable" @click="gotobefore()"></a-triangle>

      <a-triangle
        v-if="firma.fotos && firma.fotos.length>1"
        id="pf-right"
        position="2.7 1.5 -0.5"
        rotation="0 -90 -90"
        height="0.6"
        width="0.6"
        color="#FFF"
        vertex-a="0 0.2 0"
        vertex-b="-0.2 -0.2 0"
        vertex-c="0.2 -0.2wa 0"
        class="raycastable" @click="gotonext()"></a-triangle>

      <a-triangle
        v-if="firma.fotos && firma.fotos.length>1"
        id="pf-right"
        position="2.71 1.5 -0.5"
        rotation="0 -90 -90"
        height="0.6"
        width="0.6"
        color="#000"
        vertex-a="0 0.21 0"
        vertex-b="-0.21 -0.21 0"
        vertex-c="0.21 -0.21d 0"
        class="raycastable" @click="gotonext()"></a-triangle>


      <a-triangle
        v-if="false && firma.Video"
        id="pf-right"
        position="2.71 1.5 3"
        rotation="0 -90 -90"
        height="0.6"
        width="0.6"
        color="#fff"
        vertex-a="0 0.15 0"
        vertex-b="-0.15 -0.15 0"
        vertex-c="0.15 -0.15 0"
        ></a-triangle>

      <a-text
        v-if="firma.Video"
        :font="publicPath+'/3dfont/ClanOffc-NarrMedium-msdf.json'"
        align="center"
        baseline="top"
        negate="false"
        :color="contrastColor()"
        width="1.2"
        value="Bildschirm anklicken zum Video ansehen, \n nochmals Klicken zum stoppen"
        position="2.8 0.9 3"
        rotation="0 -90 0" class="raycastable"
        @click="clickHandler()"
      />
      <a-plane
        v-if="firma.Video"
        color="white"
        position="2.87 1.5 3"
        rotation="0 -90 0"
        width="1.8777"
        roughness="0"
        height="1.1" class="raycastable"
        @click="clickHandler()"
      />


      <a-video
        v-if="firma.Video"
        position="2.85 1.5 3"
        rotation="0 -90 0"
        width="1.7777"
        height="1"
        preload="false"
        autoplay="false"
        loop="false"
        webkit-playsinline playsinline
        controls class="raycastable"
        :src="'#my-video' + firma.id"
        @click="clickHandler()"
      />


      <a-box id="standtop"
             position="2.5 3 3.3"
             rotation="0 90 0"
             color="#444"
             roughness="0.6"
             scale="3.4 0.06 1."

      />

      <a-box id="standside"
             position="2.8 1.5 5"
             rotation="0 90 0"
             color="#444"
             roughness="0.6"
             scale="0.06 3 0.4"
      />

      <a-box id="wand" position="3 1.5 0"
             rotation="0 90 0"
             :color="firma.Maincolor"
             src="#wall_basecolor"
             normal-map="#wall_normal"
             normal-scale="1 1"
             ambient-occlusion-map="#wall_ambient"
             roughness-map="#wall_roughness"
             shadow="cast:true; receive:true;"
             scale="10 3 0.1"
      />

      <a-image id="av" position="-2 1. 2.2" rotation="0 -90 0" height="1.8" :src="avatar"/>

      <Hinweisschild id="avatar-offline" position="-2 1.8 2.3" :firma="firma"/>
      <Hinweisschildchat v-if="chatonline" id="avatar-online" position="-2 1.8 1.7" :firma="firma"/>

      <Staender v-if="firma.PDFs && firma.PDFs[0]" id="Staender1" position="1 0 0.0"
                :prospect="firma.PDFs[0]" :firma="firma"
      />
      <Staender v-if="firma.PDFs && firma.PDFs[1]" id="Staender2" position="1 0 0.6"
                :prospect="firma.PDFs[1]" :firma="firma"
      />
      <Staender v-if="firma.PDFs && firma.PDFs[2]" id="Staender3" position="1 0 1.2"
                :prospect="firma.PDFs[2]" :firma="firma"
      />
      <a-cylinder
        v-if="displaygameentry && false"
        @click="gotogame()" class="raycastable"
        position="-1 0.15 -2" radius="0.15" height="0.3" color="orange"

      />

            <a-box id="spawn"
                   position="-6 1.6 0"
                   visible="false"
                   color="red"
                   scale="0.3 0.3 0.3"
            />
    </a-entity>
  </a-entity>
</template>

<script>

import Staender from '@/components/stand/staender'
import Hinweisschild from '@/components/stand/hinweisschild'
import Hinweisschildchat from './hinweisschildchat'

function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
}

function invertColor(hex, bw) {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color: ' + hex);
  }
  var r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    // http://stackoverflow.com/a/3943023/112731
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186
      ? '#000000'
      : '#FFFFFF';
  }
  // invert color components
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  // pad each with zeros and return
  return "#" + padZero(r) + padZero(g) + padZero(b);
}

export default {
  components: {Hinweisschildchat, Staender, Hinweisschild},
  props: ['firma', 'displaygameentry'],
  data() {
    return {
      currentimgindex: 0,
      publicPath: process.env.BASE_URL
    }
  },
  computed: {

    avatar() {
      function getRandomInt(min, max) {
        min = Math.ceil(min)
        max = Math.floor(max)
        return Math.floor(Math.random() * (max - min + 1)) + min
      }
      let fileName=''
      if (this.firma.avatar) {
          fileName = 'avatar' + this.firma.avatar
      }else {
          fileName = 'avatar' + getRandomInt(1, 5)
      }

      return require(`../../assets/avatare/${fileName}.png`) // the module request

    },
    beforeimagexists() {
      let before = this.currentimgindex - 1
      return !!this.firma.fotos[before]
    },
    chatonline() {
      let firma = this.firma

      let firms = JSON.parse(JSON.stringify(this.$parent.$parent.$refs.chatwidget.firms))
      // console.log(firms)
      let valObj = firms.filter(function (elem) {
        return (parseInt(elem.firmaid) === parseInt(firma.id))
      })

      return valObj.length > 0

    },
    currentimg() {
      return this.firma.fotos[this.currentimgindex]
    },
    nextimagexists() {
      let next = this.currentimgindex + 1
      return !!this.firma.fotos[next]
    },
  },
  beforeDestroy() {
    let videoEl = document.querySelector('#my-video' + this.firma.id)

    if (videoEl && !videoEl.paused) {
      videoEl.pause()
    }
  },
  methods: {
    beforeimagexists2() {
      let before = this.currentimgindex - 1
      return !!this.firma.fotos[before]
    },
    clickHandler: function () {
      let videoEl = document.querySelector('#my-video' + this.firma.id)
      if (!videoEl) return
      if (videoEl.paused) {
        this.$gtag.event('click', {
          event_category: 'video',
          event_label: 'Firmenvideo ' + this.firma.Firma,
          value: 1
        })
        videoEl.play()
      } else {
        videoEl.pause()
      }
    },
    // https://stackoverflow.com/questions/35969656/how-can-i-generate-the-opposite-color-according-to-current-color

    contrastColor() {

      let colori = this.firma.Maincolor

      if (colori)
        return invertColor(colori, true)
      return '#000000';
    },
    gotobefore() {
      this.$gtag.event('slider', {
        event_category: 'left',
        event_label: 'Firma ' + this.firma.Firma,
        value: 1
      })
      if (this.beforeimagexists) {
        this.currentimgindex--
        return
      }

      this.currentimgindex = this.firma.fotos.length - 1
    },
    gotogame: function () {
      this.$router.push({name: 'Game', params: {id: this.$parent.id}});
    },
    gotonext() {
      this.$gtag.event('slider', {
        event_category: 'right',
        event_label: 'Firma ' + this.firma.Firma,
        value: 1
      })
      if (this.nextimagexists) {
        this.currentimgindex++
        return
      }

      this.currentimgindex = 0
    }
  },
}
</script>
