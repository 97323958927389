<template>
  <div>
    <div style="position:fixed;right:0;top:0;z-index: 99998;">
      <router-link
        style="border-bottom-left-radius: 10px; background: white;padding: 20px 10px ;padding-left:20px;display:inline-block;z-index: 99998;  cursor: pointer;text-align: center"
                 :to="{ name: 'Home' }">
      <svg viewBox="0 0 20 20" fill="currentColor" class="arrow-circle-left w-6 h-6" style="width: 32px;height:32px">
        <path fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z"
              clip-rule="evenodd"></path>
      </svg>
      <br/>Zurück<br/><span style="opacity: 0">-</span>
    </router-link>
      <a
        style="background: white;padding: 20px  10px;  display:inline-block; z-index: 99998;cursor: pointer;text-align:center"
       @click="mapvisible=true">
      <svg viewBox="0 0 20 20" fill="currentColor" class="map w-6 h-6" style="width: 32px;height:32px">
        <path fill-rule="evenodd"
              d="M12 1.586l-4 4v12.828l4-4V1.586zM3.707 3.293A1 1 0 002 4v10a1 1 0 00.293.707L6 18.414V5.586L3.707 3.293zM17.707 5.293L14 1.586v12.828l2.293 2.293A1 1 0 0018 16V6a1 1 0 00-.293-.707z"
              clip-rule="evenodd"></path>
      </svg>
      <br/>Hallen-<br/> übersicht
    </a>
      <a
        style="background: white;padding: 20px  10px; display:inline-block;z-index: 99998;cursor: pointer;text-align:center"
        @click="helpvisible=true">

        <svg viewBox="0 0 20 20" fill="currentColor" class="map w-6 h-6" style="width: 32px;height:32px">
          <path fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                clip-rule="evenodd"/>

        </svg>
        <br/>Navigations-<br> hilfe
      </a>
    </div>


    <div v-if="mapvisible===true" class="box"
         style="background-color:white;border-bottom-left-radius: 10px;margin-right: 0;display:block;position:fixed;right:0;top:0;z-index: 99999">
      <div style="display: flex">
        <h2 style=" flex-grow: 1">Aussteller</h2>
        <a style="display:block;  z-index: 99999;cursor: pointer;text-align:center"
           @click="mapvisible=false" v-if="mapvisible===true">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"
               style="width: 32px;height:32px">
            <path fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clip-rule="evenodd"/>
          </svg>
        </a>
      </div>

      <ul>
        <li v-for="firma in firmenequal" :key="firma.id">
          <a @click="goto(firma.id);mapvisible=false;">
            {{ firma.Firma }}
          </a>
        </li>
      </ul>
    </div>
    <div v-if="helpvisible===true"
         style="display:block;position:fixed;left: 50%;top: 50%;transform: translate(-50%, -50%);z-index: 99999">
      <div style="display: flex">

        <a style="display:block;  z-index: 99999;cursor: pointer;text-align:center"
           @click="helpvisible=false">

          <img src="@/assets/logo/overlay.png" style="max-width: 90%">
        </a>
      </div>


    </div>
    <!--<chat ref="chatwidget" :chatvisible="chatvis"/>
     antialias="true"  stats -->
    <a-scene v-if="id" vr-mode-ui="enabled: false" joystick embedded class="vrscreen" renderer="sortObjects: true;">
      <a-assets>
        <img id="sky" src="../assets/material/skybox/skybox0.png">

        <img id="floor_basecolor" src="../assets/material/floor/Stone_Tiles_004_basecolor.jpg">
        <img id="floor_normal" src="../assets/material/floor/Stone_Tiles_004_normal.jpg">
        <img id="floor_ambient" src="../assets/material/floor/Stone_Tiles_004_ambientOcclusion.jpg">
        <img id="floor_roughness" src="../assets/material/floor/Stone_Tiles_004_roughness.jpg">

        <img id="wall_basecolor" src="../assets/material/Wallpaper/Wallpaper_Glassweave_001_basecolor.jpg">
        <img id="wall_normal" src="../assets/material/Wallpaper/Wallpaper_Glassweave_001_normal.jpg">
        <img id="wall_ambient" src="../assets/material/Wallpaper/Wallpaper_Glassweave_001_ambientOcclusion.jpg">
        <img id="wall_roughness" src="../assets/material/Wallpaper/Wallpaper_Glassweave_001_roughness.jpg">

        <img id="mail" src="../assets/icons/mail.png">
        <img id="tel" src="../assets/icons/tel.png">
        <img id="chat" src="../assets/icons/chat.png">
        <img id="web" src="../assets/icons/web.png">

        <img id="pfeil" src="../assets/material/pfeil.png">
        <img id="pfeilleft" src="../assets/material/pfeil-left.png">
        <!--
                <a-asset-item id="tafel" src="material/tafel.gltf"></a-asset-item>
                <a-asset-item id="staender" src="material/staender.gltf"></a-asset-item>
        -->
        <video
          v-for="firma in firmenvideos" :id="'my-video' + firma.id" :key="firma.id"
          crossorigin="anonymous" :src="firma.Video" preload="none"
        />
      </a-assets>
      <a-entity id="cameraRig">
        <a-camera id="camera" look-controls wasd-controls="acceleration:50" user-height="1.6">
          <!--   position="0 1.6 0"  animation="property: position; to: -4 1.6 0; dur: 3000; easing: linear; loop: false"
                    animation__rot="property: rotation; to: 0 -90 0; dur: 2000; easing: linear; loop: false"-->

          <a-entity cursor="rayOrigin: mouse" raycaster="objects: .raycastable"/>
        </a-camera>
      </a-entity>

      <a-sky src="#sky" color="#fff"/>

      <a-entity id="pointlight1" light="type:point; color: #fff;castShadow:true;intensity:0.5"
                position="20 40 -20"
      />
      <!-- <a-sphere position="20 14 -10" radius="0.25" color="yellow"></a-sphere>-->

      <a-entity id="pointlight2" light="type: point; color: #fff;castShadow:true;intensity:0.5"
                position="-20 50 20"
      />
      <!--  <a-sphere position="0 20 10" radius="0.25" color="yellow"></a-sphere>-->

      <a-entity id="ambientlight" light="type: ambient; color: #888;"/>

      <domev2 :hallid="currenthallmapp"/>


      <a-entity
        v-for="thishall in currenthall" :key="thishall.id"
        :position="' 1 0.15 0.15 '"
        rotation="-90 -90 0"
        geometry="primitive: plane; height: 1; width: 4"
        :material="'color: '+thishall.mcolor"
        :text="'width: 4;font:'+publicPath+'/3dfont/ClanOffc-NarrMedium-msdf.json;negate:false; align:center;color:#000000;value: Halle: '+thishall.name"
      />

      <!--    <a-entity
        position=" -3 2 -5"
        rotation="0 0 0"
        geometry="primitive: plane; height: 1.6; width: 4"
        material="color: black"
        :text="'width: 3;font:'+publicPath+'/3dfont/ClanOffc-NarrMedium-msdf.json;negate:false; align:center;value: Desktop: Klicken und ziehen Sie, um sich umzusehen.\n'+
'Verwenden Sie die Pfeiltasten oder WASD, um sich zu bewegen.\n'+
'\n'+
'Mobil: Neigen oder Wischen, um sich umzusehen.\n'+
'Tippen und Ziehen in der unteren linken Ecke, um sich zu bewegen.'"
        look-at="#camera"
          />-->

      <a-entity v-if="firmen[0]" layout="type: circle; radius: 12;plane:xz" position="0 0 0">
        <stand v-if="firmen[0]" :id="'stand-' + firmen[0].id" look-at="#fussboden" :displaygameentry="gameentryid===0"
               :firma="firmen[0]"/>
        <stand v-if="firmen[1]" :id="'stand-' + firmen[1].id" look-at="#fussboden" :displaygameentry="gameentryid===1"
               :firma="firmen[1]"/>
        <stand v-if="firmen[2]" :id="'stand-' + firmen[2].id" look-at="#fussboden" :displaygameentry="gameentryid===2"
               :firma="firmen[2]"/>
        <stand v-if="firmen[3]" :id="'stand-' + firmen[3].id" look-at="#fussboden" :displaygameentry="gameentryid===3"
               :firma="firmen[3]"/>
        <stand v-if="firmen[4]" :id="'stand-' + firmen[4].id" look-at="#fussboden" :displaygameentry="gameentryid===4"
               :firma="firmen[4]"/>
        <stand v-if="firmen[5]" :id="'stand-' + firmen[5].id" look-at="#fussboden" :displaygameentry="gameentryid===5"
               :firma="firmen[5]"/>
      </a-entity>

      <a-entity v-if="firmen[6]" layout="type: circle; radius: 24;plane:xz" position="0 0 0">
        <stand v-if="firmen[6]" :id="'stand-' + firmen[6].id" look-at="#fussboden" :displaygameentry="gameentryid===6"
               :firma="firmen[6]"/>
        <stand v-if="firmen[7]" :id="'stand-' + firmen[7].id" look-at="#fussboden" :displaygameentry="gameentryid===7"
               :firma="firmen[7]"/>
        <stand v-if="firmen[8]" :id="'stand-' + firmen[8].id" look-at="#fussboden" :displaygameentry="gameentryid===8"
               :firma="firmen[8]"/>
        <stand v-if="firmen[9]" :id="'stand-' + firmen[9].id" look-at="#fussboden" :displaygameentry="gameentryid===9"
               :firma="firmen[9]"/>
        <stand v-if="firmen[10]" :id="'stand-' + firmen[10].id" look-at="#fussboden"
               :displaygameentry="gameentryid===10"
               :firma="firmen[10]"/>
        <stand v-if="firmen[11]" :id="'stand-' + firmen[11].id" look-at="#fussboden"
               :displaygameentry="gameentryid===11"
               :firma="firmen[11]"/>
        <stand v-if="firmen[12]" :id="'stand-' + firmen[12].id" look-at="#fussboden"
               :displaygameentry="gameentryid===12"
               :firma="firmen[12]"/>
        <stand v-if="firmen[13]" :id="'stand-' + firmen[13].id" look-at="#fussboden"
               :displaygameentry="gameentryid===13"
               :firma="firmen[13]"/>
        <stand v-if="firmen[14]" :id="'stand-' + firmen[14].id" look-at="#fussboden"
               :displaygameentry="gameentryid===14"
               :firma="firmen[14]"/>
        <stand v-if="firmen[15]" :id="'stand-' + firmen[15].id" look-at="#fussboden"
               :displaygameentry="gameentryid===15"
               :firma="firmen[15]"/>
        <stand v-if="firmen[16]" :id="'stand-' + firmen[16].id" look-at="#fussboden"
               :displaygameentry="gameentryid===16"
               :firma="firmen[16]"/>
        <stand v-if="firmen[17]" :id="'stand-' + firmen[17].id" look-at="#fussboden"
               :displaygameentry="gameentryid===17"
               :firma="firmen[17]"/>
      </a-entity>

      <a-entity v-if="firmen[18]" layout="type: circle; radius: 34;plane:xz" position="0 0 0">
        <stand v-if="firmen[18]" :id="'stand-' + firmen[18].id" look-at="#fussboden"
               :displaygameentry="gameentryid===18"
               :firma="firmen[18]"/>
        <stand v-if="firmen[19]" :id="'stand-' + firmen[19].id" look-at="#fussboden"
               :displaygameentry="gameentryid===19"
               :firma="firmen[19]"/>
        <stand v-if="firmen[20]" :id="'stand-' + firmen[20].id" look-at="#fussboden"
               :displaygameentry="gameentryid===20"
               :firma="firmen[20]"/>
        <stand v-if="firmen[21]" :id="'stand-' + firmen[21].id" look-at="#fussboden"
               :displaygameentry="gameentryid===21"
               :firma="firmen[21]"/>
        <stand v-if="firmen[22]" :id="'stand-' + firmen[22].id" look-at="#fussboden"
               :displaygameentry="gameentryid===22"
               :firma="firmen[22]"/>
        <stand v-if="firmen[23]" :id="'stand-' + firmen[23].id" look-at="#fussboden"
               :displaygameentry="gameentryid===23"
               :firma="firmen[23]"/>
        <stand v-if="firmen[24]" :id="'stand-' + firmen[24].id" look-at="#fussboden"
               :displaygameentry="gameentryid===24"
               :firma="firmen[24]"/>
        <stand v-if="firmen[25]" :id="'stand-' + firmen[25].id" look-at="#fussboden"
               :displaygameentry="gameentryid===25"
               :firma="firmen[25]"/>
        <stand v-if="firmen[26]" :id="'stand-' + firmen[26].id" look-at="#fussboden"
               :displaygameentry="gameentryid===26"
               :firma="firmen[26]"/>
        <stand v-if="firmen[27]" :id="'stand-' + firmen[27].id" look-at="#fussboden"
               :displaygameentry="gameentryid===27"
               :firma="firmen[27]"/>
        <stand v-if="firmen[28]" :id="'stand-' + firmen[28].id" look-at="#fussboden"
               :displaygameentry="gameentryid===28"
               :firma="firmen[28]"/>
        <stand v-if="firmen[29]" :id="'stand-' + firmen[29].id" look-at="#fussboden"
               :displaygameentry="gameentryid===29"
               :firma="firmen[29]"/>
        <stand v-if="firmen[30]" :id="'stand-' + firmen[30].id" look-at="#fussboden"
               :displaygameentry="gameentryid===30"
               :firma="firmen[30]"/>
        <stand v-if="firmen[31]" :id="'stand-' + firmen[31].id" look-at="#fussboden"
               :displaygameentry="gameentryid===31"
               :firma="firmen[31]"/>
        <stand v-if="firmen[32]" :id="'stand-' + firmen[32].id" look-at="#fussboden"
               :displaygameentry="gameentryid===32"
               :firma="firmen[32]"/>
      </a-entity>

      <a-plane id="fussboden" position="0 -0.0001 0" rotation="-90 0 0" width="10000" height="10000"
               color="#fff"
               shadow="receive:true;"
               src="#floor_basecolor"
               normal-map="#floor_normal"
               normal-texture-repeat="2500 2500"
               normal-scale="-1 1"
               repeat="2500 2500"
               ambient-occlusion-map="#floor_ambient"

      />
    </a-scene>
  </div>
</template>


<script>


require('aframe')
require('aframe-layout-component')
require('aframe-look-at-component')
require('../joystick.js')


// eslint-disable-next-line
AFRAME.components["look-controls"].Component.prototype.onTouchMove = function (evt) {
  var canvas = this.el.sceneEl.canvas;
  var deltaX, deltaY;
  var pitchObject = this.pitchObject;
  var yawObject = this.yawObject;

  if (!this.touchStarted || !this.data.touchEnabled) {
    return;
  }
  let PI_2 = Math.PI / 2;
  deltaY = 2 * Math.PI * (evt.touches[0].pageX - this.touchStart.x) / canvas.clientWidth;
  deltaX = 2 * Math.PI * (evt.touches[0].pageY - this.touchStart.y) / canvas.clientHeight;

  // Allow touch orientaion to both x and y
  yawObject.rotation.y -= deltaY * 0.5;
  pitchObject.rotation.x -= deltaX * 0.5;

  pitchObject.rotation.x = Math.max(-PI_2, Math.min(PI_2, pitchObject.rotation.x));
  this.touchStart = {
    x: evt.touches[0].pageX,
    y: evt.touches[0].pageY
  };

}


import Stand from '@/components/stand/stand'
import Domev2 from '@/components/hall/domev2'
import filter from 'lodash/filter'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'


// https://glitch.com/edit/#!/aframe-lookat-camera-working?path=script.js%3A28%3A16
function getVector(camera, targetObject) {
  var entityPosition = new THREE.Vector3(); // eslint-disable-line
  targetObject.object3D.getWorldPosition(entityPosition);

  var cameraPosition = new THREE.Vector3();// eslint-disable-line
  camera.object3D.getWorldPosition(cameraPosition);

  //Based on:  https://github.com/supermedium/superframe/blob/master/components/look-at/index.js
  var vector = new THREE.Vector3(entityPosition.x, entityPosition.y, entityPosition.z);// eslint-disable-line
  vector.subVectors(cameraPosition, vector).add(cameraPosition);
  return vector;
}

function centerCamera(camera, vector) {
  //Based on: https://codepen.io/wosevision/pen/JWRMyK
  camera.object3D.lookAt(vector);
  camera.object3D.updateMatrix();

  //Based on: https://stackoverflow.com/questions/36809207/aframe-threejs-camera-manual-rotation
  var rotation = camera.getAttribute('rotation');
  camera.components['look-controls'].pitchObject.rotation.x = THREE.Math.degToRad(rotation.x);// eslint-disable-line
  camera.components['look-controls'].yawObject.rotation.y = THREE.Math.degToRad(rotation.y);// eslint-disable-line
}

function lookAtObject(objectid) {
  //console.log(object)
  let cameraEl = document.getElementById('camera');
  //console.log(cameraEl)
  cameraEl.setAttribute("look-controls", {enabled: false});
  let object = document.querySelector(objectid)
  //console.log(object)
  let pointTarget = getVector(cameraEl, object);
  centerCamera(cameraEl, pointTarget);
  cameraEl.setAttribute("look-controls", {enabled: true});
}

export default {
  name: 'Hall',
  components: {
    Domev2,
    Stand,
  },
  props: ['id', 'stand'],
  data() {
    return {
      mapvisible: false,
      helpvisible: true,
      gameentryid: false,
      publicPath: process.env.BASE_URL,
    }
  },
  computed: {
    chatvis() {
      return this.$root.chatvisible
    },

    currenthall() {
      // return this.$root.hallen
      let self = this
      let hallenid = parseInt(self.id)

      let hallen = filter(this.$root.hallen, function (h) {
        return parseInt(h.id) === parseInt(hallenid)
      })
      return hallen
    },
    currenthallmapp() {
      // return this.$root.hallen
      let self = this
      let hallenid = parseInt(self.id)
      return hallenid
      //console.log(self.id)
      /* switch (hallenid) {
         case 1:
           return 1
         case 2:
           return 2
         case 3:
           return 3
         case 6:
           return 4
         case 8:
           return 5
         case 9:
           return 6
         case 10:
           return 7
       }
       return 1*/
    },
    firmen() {

      let self = this

      let teilnehmer = filter(this.$root.teilnehmer, function (tn) {
        return parseInt(tn.Halle) === parseInt(self.id)
      })

      teilnehmer = map(teilnehmer, function (data) {
        //console.log(data)
        if (data.id == 7 || data.id == 58 || data.id == 57) {
          data.prio = 1
        } else {
          data.prio = 2
        }
        return data
      })
      const nameSorter = Firma => Firma.Firma.toLowerCase()

      teilnehmer = sortBy(teilnehmer, ['prio', nameSorter])
      //console.log(teilnehmer)
      return teilnehmer
    },
    firmenequal() {

      let self = this

      let teilnehmer = filter(this.$root.teilnehmer, function (tn) {
        return parseInt(tn.Halle) === parseInt(self.id)
      })

      const nameSorter = Firma => Firma.Firma.toLowerCase()
      teilnehmer = sortBy(teilnehmer, [nameSorter])
      //console.log(teilnehmer)
      return teilnehmer
    },
    firmenvideos() {

      let self = this

      return filter(this.$root.teilnehmer, function (tn) {
        return parseInt(tn.Halle) === parseInt(self.id) && tn.Video
      })
    },
  },

  mounted() {
    //console.log(this.id, this.stand)

    if (this.stand) {
      this.goto(this.stand)
    }

    function getRandomInt(min, max) {
      min = Math.ceil(min)
      max = Math.floor(max)

      return parseInt(Math.floor(Math.random() * (max - min + 1)) + min)
    }

    this.gameentryid = getRandomInt(0, this.firmen.length)

  },
  methods: {
    closeAussteller: function () {
      this.mapvisible = false
    },
    goto: function (standid) {

      this.$gtag.event('click', {
        event_category: 'goto',
        event_label: 'Stand ' + standid,
        value: 1
      })

      let checkExist = setInterval(function () {

        let targetpostion = '#stand-' + standid + ' #spawn'
        //console.log(targetpostion)
        let el = document.querySelector(targetpostion)
        //console.log(el)
        if (el
          && document.querySelector(targetpostion).getAttribute('position').x
          && document.querySelector(targetpostion).getAttribute('position').y
        ) {
          // console.log("Exists!", el);

          //document.querySelector('#camera').emit('gotoanim');

          let worldPos = new THREE.Vector3();// eslint-disable-line
          worldPos.setFromMatrixPosition(el.object3D.matrixWorld);
          console.log(worldPos);

          console.log(worldPos.y)
          console.log(worldPos.y > 0)
          if (worldPos.y)
            clearInterval(checkExist);
          else
            return

          document.querySelector('#camera').setAttribute('position', worldPos)
          lookAtObject('#stand-' + standid)

        } else {
          //console.log("wait for el");
        }
      }, 500); // check every 100ms


    },
  },
}
</script>
<style lang="scss">
.vrscreen {
  width: 100%;
  height: 100vh;
  display: block;
}

.box {
  max-width: 90%;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  margin-right: 20px;
  margin-left: 20px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      margin-bottom: 8px;
      border-radius: 10px;
      cursor: pointer;

      a {
        font-family: "Museo Slab W01_300", sans-serif;
        display: block;
        color: #333;

      }
    }
  }


  h2 {
    margin-top: 0;
  }
}
</style>


